$prime-blue: #2b4bf2;

.langingPage__wrapper {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 1160px) {
    border-top: 3px solid #d9d9d9;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }

  @media screen and (max-width: 430px) {
    border-top: 3px solid #d9d9d9;
    width: 100%;
    max-width: 425px;
    overflow-x: hidden;
  }
}

.video__foreword__container {
  margin: 0 auto;
  margin-top: 65px;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 1024px;
  }
  @media screen and (max-width: 430px) {
    margin-top: 0px;
    width: 100%;
    max-width: 425px;
    flex-direction: column-reverse;
  }
}

.foreword {
  margin-left: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1246px) {
    margin-left: 58px;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 620px;
    p {
      font-size: 20px !important;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 430px;
    p {
      font-size: 16px !important;
    }
  }

  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 40px;
    p {
      font-size: 12px !important;
    }
  }

  h3 {
    font-size: 36px !important;
    line-height: 110% !important;
    font-family: "Roboto Slab", serif !important;
    margin: 0;
    margin-bottom: 20px !important;
    font-weight: bold !important;
    text-align: center;
    @media screen and (max-width: 430px) {
      font-size: 32px !important;
    }
  }

  p {
    width: 456px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.01em;
    margin-bottom: 0;
    color: #000000;

    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 780px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      max-width: 520px;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 425px;
      margin: 0;
      font-size: 12px;
      line-height: 130%;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }
}

.mainImg {
  height: 800px;
  .centerText {
    margin: 0 auto;
  }
  h1 {
    color: white;
    font-size: 64px;
    margin: 0;
  }
  button {
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  img {
    margin-left: 5px;
  }
}

.flexBenefits {
  display: flex;
  justify-content: center;
  width: 1081px;
  margin: 0 auto;
  margin-bottom: 42px;
  @media screen and (max-width: 1140px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 520px;
  }

  @media screen and (max-width: 568px) {
    width: 100%;
    max-width: 425px;
  }

  .first__benefits__wrapper {
    position: relative;

    @media screen and (max-width: 768px) {
      svg {
        width: 234px;
        height: auto;
      }
    }

    @media screen and (max-width: 568px) {
      svg {
        width: 175px;
        height: auto;
      }
    }
    .first__benefits__title {
      text-align: center;
      position: absolute !important;
      top: 90px !important;
      left: 70px;
      margin: 0 !important;

      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: 36px;
      }

      @media screen and (max-width: 568px) {
        top: 45px !important;
        left: 32px;
      }
    }
  }

  .second__benefits__wrapper {
    position: relative;
    margin-left: 48px;

    @media screen and (max-width: 768px) {
      svg {
        width: 208px;
        height: auto;
      }
    }

    @media screen and (max-width: 568px) {
      margin-left: 28px;
      svg {
        width: 150px;
        height: auto;
      }
    }

    .second__benefits__title {
      width: 277px;
      position: absolute !important;
      top: 90px !important;
      left: 10px;
      margin: 0 !important;
      text-align: center !important;

      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -33px;
      }

      @media screen and (max-width: 568px) {
        top: 45px !important;
        left: 5px;
        width: 146px;
      }
    }
  }

  .third__benefits__wrapper {
    position: relative;
    @media screen and (max-width: 768px) {
      svg {
        width: 240px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      svg {
        width: 175px;
        height: auto;
      }
    }
    .third__benefits__title {
      width: 257px;
      text-align: center;
      position: absolute !important;
      top: 65px !important;
      left: 50px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -3px;
      }
      @media screen and (max-width: 568px) {
        width: 130px;
        top: 33px !important;
        left: 23px;
      }
    }
  }
  .fourth__benefits__wrapper {
    position: relative;
    margin-left: 48px;
    @media screen and (max-width: 768px) {
      svg {
        width: 194px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      margin-left: 34px;
      svg {
        width: 141px;
        height: auto;
      }
    }
    .fourth__benefits__title {
      width: 214px;
      text-align: center;
      position: absolute !important;
      top: 65px !important;
      left: 30px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -8px;
      }
      @media screen and (max-width: 568px) {
        width: 108px;
        top: 33px !important;
        left: 16px;
      }
    }
  }

  .fifth__benefits__wrapper {
    position: relative;
    @media screen and (max-width: 768px) {
      svg {
        width: 193px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      svg {
        width: 155px;
        height: auto;
      }
    }
    .fifth__benefits__title {
      width: 257px;
      text-align: center;
      position: absolute !important;
      top: 119px !important;
      left: 35px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -32px;
      }
      @media screen and (max-width: 568px) {
        top: 57px !important;
        left: 18px;
        width: 118px;
      }
    }
  }
  .sixth__benefits__wrapper {
    position: relative;
    margin-left: 48px;
    @media screen and (max-width: 768px) {
      svg {
        width: 193px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      margin-left: 37px;
      svg {
        width: 155px;
        height: auto;
      }
    }
    .sixth__benefits__title {
      width: 214px;
      text-align: center;
      position: absolute !important;
      top: 119px !important;
      left: 38px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -10px;
      }
      @media screen and (max-width: 568px) {
        top: 57px !important;
        left: 21px;
        width: 109px;
      }
    }
  }

  .seventh__benefits__wrapper {
    position: relative;
    @media screen and (max-width: 768px) {
      svg {
        width: 200px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      svg {
        width: 161px;
        height: auto;
      }
    }
    .seventh__benefits__title {
      width: 271px;
      text-align: center;
      position: absolute !important;
      top: 124px !important;
      left: 29px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -35px;
      }
      @media screen and (max-width: 568px) {
        top: 60px !important;
        left: 10px;
        width: 145px;
      }
    }
  }
  .eighth__benefits__wrapper {
    position: relative;
    margin-left: 48px;
    @media screen and (max-width: 768px) {
      svg {
        width: 200px;
        height: auto;
      }
    }
    @media screen and (max-width: 568px) {
      margin-left: 26px;
      svg {
        width: 158px;
        height: auto;
      }
    }
    .eighth__benefits__title {
      width: 281px;
      text-align: center;
      position: absolute !important;
      top: 124px !important;
      left: 27px;
      margin: 0 !important;
      @media screen and (max-width: 768px) {
        top: 54px !important;
        left: -40px;
      }
      @media screen and (max-width: 568px) {
        top: 60px !important;
        left: 6px;
        width: 139px;
      }
    }
  }
}

.benefitsBlock {
  text-align: center;
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 430px;
  }
  button {
    background-color: $prime-blue;
    width: 200px;
    text-align: center;
  }
  p {
    font-size: 22px;
    line-height: 140%;
    font-family: "Roboto", sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  h4 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    line-height: 130%;
    font-size: 20px;
    margin: 0;
  }
  h3 {
    font-size: 36px !important;
    line-height: 110% !important;
    font-family: "Roboto Slab", serif !important;
    margin: 0;
    margin-top: 90px;
    margin-bottom: 32px !important;
    font-weight: bold !important;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 26px !important;
    }

    @media screen and (max-width: 568px) {
      font-size: 19px !important;
      margin-top: 18px !important;
    }
  }
}

.getApp__block {
  display: flex;
  position: relative;
  margin: 0 auto;
  margin-top: 174px;
  width: 100%;
  max-width: 1140px;
  border: 2px solid #d9dada;
  border-radius: 45px;
  padding: 27px;
  @media screen and (max-width: 1240px) {
    max-width: 980px;
  }
  @media screen and (max-width: 1070px) {
    max-width: 900px;
  }
  @media screen and (max-width: 980px) {
    max-width: 800px;
  }
  @media screen and (max-width: 890px) {
    max-width: 760px;
  }
  @media screen and (max-width: 840px) {
    max-width: 670px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 130px;
    max-width: 500px;
  }
  @media screen and (max-width: 570px) {
    margin-top: 70px;
    max-width: 400px;
  }
  @media screen and (max-width: 470px) {
    max-width: 350px;
  }
  @media screen and (max-width: 430px) {
    max-width: 300px;
  }
  @media screen and (max-width: 365px) {
    max-width: 275px;
  }

  .getApp__title {
    width: 260px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: -20px;
    left: 40%;
    background-color: white;
    @media screen and (max-width: 1240px) {
      left: 37%;
    }
    @media screen and (max-width: 1070px) {
      left: 35%;
    }
    @media screen and (max-width: 840px) {
      left: 33%;
    }
    @media screen and (max-width: 768px) {
      left: 28%;
      top: -20px;
    }
    @media screen and (max-width: 570px) {
      left: 28%;
      width: 200px;
      top: -29px;
    }
    @media screen and (max-width: 470px) {
      left: 25%;
    }
    @media screen and (max-width: 430px) {
      left: 23%;
    }
    @media screen and (max-width: 365px) {
      left: 20%;
    }
  }
  .getApp__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }
  .app_blockName {
    margin: 69px 0 0 0;
    text-align: left;
  }
  .download__block {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
    }
    .app__link {
      width: inherit;
      color: black;
      text-decoration: none;
    }
  }
  .download__link {
    width: 100%;
    max-width: 640px;
    height: 84px;
    background-color: #2b4bf2;
    display: flex;
    flex-direction: row;
    padding: 7px;
    border-radius: 50px;
    @media screen and (max-width: 570px) {
      height: 60px;
    }
    .link__textarea {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: 700;
      font-size: 25.7143px;
      line-height: 110%;
      width: 532px;
      height: 100%;
      background-color: white;
      border-radius: 50px;
      @media screen and (max-width: 570px) {
        font-size: 19px;
      }
      @media screen and (max-width: 430px) {
        font-size: 16px;
      }
    }
    .download__svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 108px;
      svg {
        height: 50px;
        width: 50px;
        @media screen and (max-width: 570px) {
          width: 43px;
          height: 43px;
        }
        @media screen and (max-width: 430px) {
          width: 38px;
          height: 38px;
        }
      }
    }
    .YTImg {
      width: 58px;
      height: 58px;
      @media screen and (max-width: 430px) {
        width: 38px;
        height: 38px;
      }
    }
    .download__svg:hover {
      cursor: pointer;
    }
  }
  .qrImg {
    width: 128px;
    height: 128px;
    @media screen and (max-width: 890px) {
      display: none;
    }
  }
}

.cardBenefitFirst {
  margin-right: 34px;
}

.cardBenefitSecond {
  margin-right: 93px;
}

.flexContainer {
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 120px !important;
  display: flex;
  width: 100%;
  max-width: 1193px;
  flex-direction: row-reverse;
  @media screen and (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  @media screen and (max-width: 530px) {
    justify-content: center;
    width: 100%;
    max-width: 425px;
    margin-top: 0px;
    margin-bottom: 28px !important;
  }

  .glassImg {
    width: 650px;
    height: 473px;
    @media screen and (max-width: 1200px) {
      width: 100%;
      max-width: 480px;
      height: 340px;
    }

    @media screen and (max-width: 1024px) {
      position: absolute;
      width: 100%;
      max-width: 780px;
      height: auto;
      margin: 0 auto;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 520px;
    }

    @media screen and (max-width: 530px) {
      position: absolute;
      width: 100%;
      max-width: 355px;
      height: 258px;
      margin: 0 auto;
    }
  }
}

.containerTxt {
  text-align: left;
  @media screen and (max-width: 1024px) {
    margin-top: 140px;
  }
  @media screen and (max-width: 530px) {
    margin-top: 0;
  }
  @media screen and (max-width: 430px) {
    margin-left: 0;
    margin-top: 0;
  }
  button {
    cursor: pointer;
  }
  .BlueButton__wrapper {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      margin-top: 80px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 530px) {
      width: 100%;
      max-width: 425px;
      margin: 0 auto;
      margin-top: 130px;
      button {
        margin: 0 auto;
      }
    }
  }

  .mobileTxt__wrapper {
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      top: -10px;
      position: relative;
      width: 692px;
      height: 340px;
      background: rgba(208, 208, 208, 0.3);
      backdrop-filter: blur(2px);
      border-radius: 29px;
    }
    @media screen and (max-width: 768px) {
      width: 480px;
      height: auto;
    }
    @media screen and (max-width: 530px) {
      width: 330px;
      height: auto;
      top: 36px;
    }
  }
  p {
    margin: 0;
    font-size: 22px;
    line-height: 140%;
    font-family: "Roboto", sans-serif;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 620px;
      text-align: center;
      margin: 0 auto;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
      width: 100%;
      max-width: 400px;
    }
    @media screen and (max-width: 530px) {
      width: 100%;
      max-width: 307px;
      font-size: 11px;
      line-height: 130%;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 18px;
    }
  }
  h3 {
    font-size: 36px !important;
    line-height: 110% !important;
    font-family: "Roboto Slab", serif !important;
    margin: 0;
    font-weight: bold !important;
    margin-top: 64px;
    margin-bottom: 22px !important;
    text-align: left;
    @media screen and (max-width: 1024px) {
      text-align: center;
      padding-top: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 30px !important;
    }
    @media screen and (max-width: 530px) {
      font-size: 24px !important;
      text-align: center;
      margin-bottom: 0 !important;
      padding-top: 13px;
    }
  }
}

.containerTxtSecond {
  width: 456px;
  margin-top: 42px !important;
  margin-bottom: 42px !important;

  @media screen and (max-width: 530px) {
    margin-top: 9px !important;
    margin-bottom: 30px !important;
  }
}

.groupTxt {
  h3 {
    margin-top: 24px !important;
    margin-bottom: 22px !important;
  }
  width: 526px;
  margin: 0 auto 32px auto;
  @media screen and (max-width: 570px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 430px) {
    max-width: 325px;
    margin: 0 auto 22px auto;
    p {
      margin: 0 auto;
      font-size: 12px;
    }
  }
}

.oneTxt {
  h3 {
    margin-top: 24px !important;
    margin-bottom: 22px !important;
  }
  width: 416px;
  margin: 0 auto 32px auto;
  @media screen and (max-width: 570px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 430px) {
    margin: 0 auto 22px auto;
    p {
      margin: 0 auto;
      max-width: 325px;
      font-size: 12px;
    }
  }
}

.wrapperOne {
  margin-top: -9px;
  @media screen and (max-width: 1200px) {
    button {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 430px) {
    button {
      margin-bottom: 22px;
    }
  }
}

.wrapperGroup {
  @media screen and (max-width: 430px) {
    margin-bottom: 38px;
  }
}

.flexGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    width: 50%;
  }
}

.flexType {
  width: 1140px;
  margin: 120px auto 120px auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 620px;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 570px) {
    width: 100%;
    max-width: 380px;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    flex-direction: column-reverse;
    margin: 16px 0 auto;
    margin-top: 70px;
  }
  span {
    margin-top: 30px;
    @media screen and (max-width: 430px) {
      display: none;
    }
  }
  .groupImg {
    width: 631px;
    height: 435px;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 500px;
      height: auto;
    }

    @media screen and (max-width: 430px) {
      width: 322px;
      height: auto;
      margin-top: 22px;
    }
  }
  .oneOnOneImg {
    width: 331px;
    height: 435px;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 262px;
      height: auto;
    }

    @media screen and (max-width: 430px) {
      width: 189px;
      height: auto;
    }
  }
}

.quoteBlock {
  background-size: cover;
  height: auto;
  color: white;

  @media screen and (max-width: 430px) {
    width: 100%;
    max-width: 425px;
    height: auto;
  }

  h1 {
    font-size: 64px;
    margin: 0;
    margin-bottom: 32px;
  }

  h3 {
    margin-top: 35px;
  }
}

.centerButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile__hr {
  display: none;

  @media screen and (max-width: 430px) {
    display: flex;
    border: 1.5px solid #d9d9d9;
    background-color: #d9d9d9;
    width: calc(100vw - 50px);
    margin: 0 auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}
